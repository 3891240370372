import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layouts/AppLayout.vue';
import PspAccountLayout from '@/layouts/PspAccountLayout.vue';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import QuickBookLayout from '@/layouts/QuickBooksLayout.vue';

export const routes = [
  {
    path: '/',
    component: Layout,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/AuthorizationPage.vue'),
  },
  {
    path: '/dashboard',
    component: Layout,
    children: [
      {
        path: 'psp-balances',
        name: 'PspBalancesPage',
        component: () => import('@/pages/dashboard/psp-balances/PspBalancesPage.vue'),
      },
      {
        path: 'quickbooks-transaction-by-bank',
        component: () => {},
        meta: {
          newFinance: true,
        },
      },
      {
        path: 'fee-statistics',
        component: () => {},
        meta: {
          newFinance: true,
        },
      },
    ],
  },
  {
    path: '/b2b',
    component: Layout,
    children: [
      {
        path: 'brand',
        component: () => {},
        meta: {
          newFinance: true,
        },
      },
      {
        path: 'mapping-payment-providers',
        component: () => {},
        meta: {
          newFinance: true,
        },
      },
      {
        path: 'mapping-game-providers',
        component: () => {},
        meta: {
          newFinance: true,
        },
      },
    ],
  },
  {
    path: '/psp-accounts',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Accounts',
        component: () => {},
        meta: {
          newFinance: true,
        },
      },
      {
        path: ':pspAccountId(\\d+)',
        component: PspAccountLayout,
        children: [
          {
            path: 'reports',
            name: 'PSP_ACCOUNT_REPORTS',
            component: () => import('@/pages/PspAccountReportsPage.vue'),
          },
          {
            path: 'transactions',
            name: 'PSP_ACCOUNT_TRANSACTIONS',
            component: () => import(
              '@/components/PaymentService/Accounts/Transactions/TransactionsTable.vue'
            ),
          },
          {
            path: 'top-up-balance-transactions',
            name: 'PSP_ACCOUNT_TOP_UP_BALANCE_TRANSACTIONS',
            component: () => import(
              '@/components/top-up-balance-transactions/TopUpBalanceTransactionsTable.vue'
            ),
          },
          {
            path: 'fee-configs',
            name: 'PSP_ACCOUNT_FEE_CONFIGS',
            component: () => import(
              '@/components/PaymentService/Accounts/FeeConfigs/FeeConfigsTable.vue'
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/game-providers',
    component: Layout,
    children: [
      {
        path: '',
        name: 'GameProviders',
        component: () => {},
        meta: {
          newFinance: true,
        },
      },
    ],
  },
  {
    path: '/quickbooks-transfer',
    component: Layout,
    children: [
      {
        path: '',
        name: 'QUICK_BOOKS_SETTLEMENTS_PAGE',
        component: () => {},
        meta: {
          newFinance: true,
        },
      },
      {
        path: ':quickBooksTransferId(\\d+)',
        component: QuickBookLayout,
        children: [
          {
            path: 'top-up-balance-transactions',
            name: 'QUICK_BOOKS_TOP_UP_PAGE',
            component: () => {},
            meta: {
              newFinance: true,
            },
          },
          {
            path: 'actual-settlement',
            name: 'QUICK_BOOKS_ACTUAL_SETTLEMENT_PAGE',
            component: () => {},
            meta: {
              newFinance: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/logging',
    component: Layout,
    children: [
      {
        path: '',
        name: 'LOGGING_PAGE',
        component: () => {},
        meta: {
          newFinance: true,
        },
      },
    ],
  },
  {
    path: '/affiliate',
    component: Layout,
    children: [
      {
        path: 'entity',
        component: () => import('@/pages/EmptyPage.vue'),
        meta: {
          newFinance: true,
        },
      },
      {
        path: 'register',
        component: () => import('@/pages/EmptyPage.vue'),
        meta: {
          newFinance: true,
        },
      },
      {
        path: 'company',
        component: () => import('@/pages/EmptyPage.vue'),
        meta: {
          newFinance: true,
        },
      },
      {
        path: 'group',
        component: () => import('@/pages/EmptyPage.vue'),
        meta: {
          newFinance: true,
        },
      },
    ],
  },
  {
    path: '/quickbooks-disconnect*',
    name: 'QUICKBOOKS_DISCONNECT',
    component: () => import('@/pages/QuickBooksTransferDisconnectPage.vue'),
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== 'Login'
    && to.name !== 'QUICKBOOKS_DISCONNECT'
    && !store.getters['authorizationStore/isAuthorized']
  ) {
    next({ name: 'Login', params: { redirect: to.path } });
  }

  if (to.meta.newFinance) {
    window.open(` ${window.location.origin}/-${to.path}`, '_blank');

    next(false);
  } else {
    next();
  }
});

export default router;
