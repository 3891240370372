<template>
  <div>
    <AppPreloader v-if="isLoading" />
    <el-container v-else>
      <el-header height="48px">
        <AppMenu />
      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AppMenu from '@/components/app/AppMenu.vue';
import AppPreloader from '@/components/app/AppPreloader.vue';

export default {
  name: 'AppLayout',
  components: { AppPreloader, AppMenu },
  data: () => ({
    isLoading: true,
  }),
  created() {
    this.fetchRequiredData();
  },
  methods: {
    ...mapActions('dictionaryStore', ['getCountry', 'getPspFeeType', 'getFinanceCurrency', 'getCardScheme', 'getPspBp', 'getWhiteLabels', 'getOriginCurrency', 'getQuickBookCompany']),
    ...mapActions('authorizationStore', ['fetchUserScopeRequest']),
    fetchRequiredData() {
      Promise.allSettled([
        this.fetchUserScopeRequest(),
        this.getPspFeeType(),
        this.getCountry(),
        this.getFinanceCurrency(),
        this.getCardScheme(),
        this.getPspBp(),
        this.getWhiteLabels(),
        this.getOriginCurrency(),
        this.getQuickBookCompany(),
      ]).then(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>
