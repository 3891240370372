import Vue from 'vue';
import Vuex from 'vuex';

import authorizationStore from '@/store/authorization.store';
import dictionaryStore from '@/store/dictionary.store';
import pspFeeConfig from '@/store/pspAccounts/pspFeeConfig';
import topUpBalanceTransactions from '@/store/top-up-balance-transactions.store';
import pspTransactionStore from '@/store/psp-transaction.store';
import quickBooksStore from '@/store/quick-books.store';
import settlementReportStore from '@/store/settlement-report.store';
import pspBalancesStore from '@/store/dashboard/psp-balances.store';
import pspTransactions from './pspAccounts/pspTransactions';
import pspAccounts from './pspAccounts/pspAccounts';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authorizationStore,
    dictionaryStore,
    pspAccounts,
    pspFeeConfig,
    pspTransactions,
    topUpBalanceTransactions,
    pspTransactionStore,
    quickBooksStore,
    settlementReportStore,
    pspBalancesStore,
  },
});
