import { api } from '@/services/http';
import { formatDatesInPayload, formatUnixToString } from '@/utils';
import { Notification } from 'element-ui';
import moment from 'moment-timezone';

export default {
  namespaced: true,
  state: {
    tableParams: {},
    pspAccounts: [],
    isPendingLoadingData: false,
    pspAccount: {},
    editablePspAccountId: null,
    isVisibleDrawer: false,
    pspAccountsTotals: {},
    isVisibleRecalculationModal: false,
    isVisiblePspAccountForm: false,
  },

  getters: {
    getUniquePspAccounts: (state) => {
      const uniquePspAccounts = new Set();
      const result = [];

      for (const item of state.pspAccounts) {
        if (item.pspAccountId && !uniquePspAccounts.has(item.pspAccountId)) {
          uniquePspAccounts.add(item.pspAccountId);
          result.push({
            pspAccountId: item.pspAccountId,
            pspSlug: item.pspSlug,
            provider: item.provider,
            pspAccountList: item.pspAccountList,
            whitelabelList: item.whitelabelList,
            paymentSystemList: item.paymentSystemList,
          });
        } else if (!item.pspAccountId && item.groupedByPspSlug.length) {
          for (const group of item.groupedByPspSlug) {
            if (
              group.pspAccountId
              && !uniquePspAccounts.has(group.pspAccountId)
            ) {
              uniquePspAccounts.add(group.pspAccountId);
              result.push({
                pspAccountId: group.pspAccountId,
                pspSlug: group.pspSlug,
                provider: group.provider,
                pspAccountList: group.pspAccountList,
                whitelabelList: group.whitelabelList,
                paymentSystemList: group.paymentSystemList,
              });
            }
          }
        }
      }

      return result;
    },
    getPspAccountDataForFeeConfig: (state) => {
      const {
        pspAccountList, whitelabelIdList, pspServiceList, paymentSystemList,
      } = state.pspAccount;

      return {
        pspAccountList,
        whitelabelIdList,
        pspServiceList,
        paymentSystemList,
      };
    },
    getPspTransactionReportStartDateFrom: (state) => {
      if (!state.pspAccount.pspTransactionReportStartDateFrom) {
        return moment()
          .subtract(1, 'days')
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
      }

      return formatUnixToString(
        state.pspAccount.pspTransactionReportStartDateFrom,
        true,
      );
    },
  },

  mutations: {
    setPspAccounts: (state, data) => {
      state.pspAccounts = data;
    },
    setPspAccountsIsLoading: (state, isLoading = true) => {
      state.isPendingLoadingData = isLoading;
    },
    setPspAccount: (state, pspAccount) => {
      state.pspAccount = pspAccount;
    },
    setVisibleDrawer: (state, isVisibleDrawer = true) => {
      state.isVisibleDrawer = isVisibleDrawer;
    },
    setResetState: (state) => {
      state.pspAccount = {};
    },
  },

  actions: {
    resetState({ commit }) {
      commit('setResetState');
    },
    async getPspAccountList({ commit, state }) {
      commit('setPspAccountsIsLoading');
      try {
        const { data } = await api.get('/finance/pspAccount', {
          params: { limit: 1000, offset: 0, ...state.tableParams },
        });

        commit('setPspAccounts', data);
      } catch (error) {
        throw Error();
      } finally {
        commit('setPspAccountsIsLoading', false);
      }
    },
    async getPspAccount({ commit }, id) {
      try {
        const res = await api.get(`/finance/pspAccount/${id}`);

        const { data: payload } = res;
        const data = formatDatesInPayload({
          payload,
          includeTime: true,
          unixToString: true,
        });

        if (data.settlementConfig === null) {
          data.settlementConfig = {};
        }

        commit('setPspAccount', data);
      } catch (error) {
        throw Error();
      }
    },

    async deletePspFeeConfig(context, id) {
      try {
        const res = await api.delete(`/finance/pspFeeConfig/${id}`);
        Notification.success({ title: 'Successfully', message: res?.message });
      } catch (error) {
        Notification.error({ title: 'Error', message: error?.message });
        throw Error();
      }
    },
  },
};
