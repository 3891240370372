<template>
  <div class="app-menu__wrapper" :style="{ backgroundColor: '#22313F' }">
    <div class="app-menu__logo">
      <img width="110" src="@/assets/logo/logo--light.svg" alt="Logo" />
    </div>
    <el-menu
      class="app_menu__menu"
      mode="horizontal"
      :default-active="$route.path"
      background-color="#22313F"
      text-color="#fff"
      active-text-color="#E6A23C"
      router
    >
      <el-submenu v-if="$can('FINANCE_ADMIN')" index="/dashboard">
        <template slot="title">Dashboard</template>
        <el-menu-item index="/dashboard/quickbooks-transaction-by-bank">
          Quickbooks transaction by bank
        </el-menu-item>
        <el-menu-item index="/dashboard/fee-statistics"> Fee statistics </el-menu-item>
        <el-menu-item index="/dashboard/psp-balances"> PSP balances </el-menu-item>
      </el-submenu>
      <el-submenu v-if="$can('FINANCE_ADMIN') || $can('B2B_BRAND_ADMIN')" index="/b2b">
        <template slot="title">B2B</template>
        <el-menu-item v-if="$can('FINANCE_ADMIN') || $can('B2B_BRAND_ADMIN')" index="/b2b/brand">
          Brand
        </el-menu-item>
        <el-menu-item v-if="$can('FINANCE_ADMIN')" index="/b2b/mapping-payment-providers">
          Mapping payment providers
        </el-menu-item>
        <el-menu-item v-if="$can('FINANCE_ADMIN')" index="/b2b/mapping-game-providers">
          Mapping game providers
        </el-menu-item>
      </el-submenu>
      <el-menu-item v-if="$can('FINANCE_ADMIN')" index="/psp-accounts">PSP Accounts</el-menu-item>
      <el-menu-item
        v-if="$can('FINANCE_ADMIN')" index="/quickbooks-transfer"
      >
        Quick books transfer
      </el-menu-item>
      <el-menu-item v-if="$can('FINANCE_ADMIN')" index="/game-providers">
        Game providers
      </el-menu-item>
      <el-menu-item v-if="$can('FINANCE_ADMIN')" index="/logging">
        Logging
      </el-menu-item>
      <el-submenu
        v-if="
          $can('AFFILIATE_ACCOUNTANT') ||
          $can('AFFILIATE_FINANCE_BASIC') ||
          $can('AFFILIATE_SUPER_ADMIN')
        "
        index="/affiliate"
      >
        <template slot="title">Affiliate</template>
        <el-menu-item
          v-if="
            $can('AFFILIATE_ACCOUNTANT') ||
            $can('AFFILIATE_FINANCE_BASIC') ||
            $can('AFFILIATE_SUPER_ADMIN')
          "
          index="/affiliate/register"
        >
          Register
        </el-menu-item>
        <el-menu-item
          v-if="
            $can('AFFILIATE_FINANCE_BASIC') ||
            $can('AFFILIATE_SUPER_ADMIN')
          "
          index="/affiliate/entity"
        >
          Entity
        </el-menu-item>
        <el-menu-item
          v-if="
            $can('AFFILIATE_FINANCE_BASIC') ||
            $can('AFFILIATE_SUPER_ADMIN')
          "
          index="/affiliate/group"
        >
          Group
        </el-menu-item>
        <el-menu-item
          v-if="
            $can('AFFILIATE_FINANCE_BASIC') ||
            $can('AFFILIATE_SUPER_ADMIN')
          "
          index="/affiliate/company"
        >
          Company
        </el-menu-item>
      </el-submenu>
    </el-menu>
    <el-button class="app-menu__logout" type="text" @click="logoutRequest">
      Logout
    </el-button>
  </div>
</template>

<script lang="js">
import { mapActions } from 'vuex';

export default {
  name: 'AppMenu',
  methods: {
    ...mapActions('authorizationStore', ['logoutRequest']),
  },
};
</script>
