import Vue from 'vue';
import moment from 'moment-timezone';
import ElementUI from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';

import 'element-ui/lib/theme-chalk/index.css';
// eslint-disable-next-line import/order
import locale from 'element-ui/lib/locale/lang/en';

import './assets/styles/index.scss';

Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

moment.tz.setDefault('UTC');

// eslint-disable-next-line func-names
Vue.prototype.$can = function (role) {
  const roleList = store.getters['authorizationStore/permissionList'];

  return roleList.includes(role);
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
