var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-menu__wrapper",style:({ backgroundColor: '#22313F' })},[_vm._m(0),_c('el-menu',{staticClass:"app_menu__menu",attrs:{"mode":"horizontal","default-active":_vm.$route.path,"background-color":"#22313F","text-color":"#fff","active-text-color":"#E6A23C","router":""}},[(_vm.$can('FINANCE_ADMIN'))?_c('el-submenu',{attrs:{"index":"/dashboard"}},[_c('template',{slot:"title"},[_vm._v("Dashboard")]),_c('el-menu-item',{attrs:{"index":"/dashboard/quickbooks-transaction-by-bank"}},[_vm._v(" Quickbooks transaction by bank ")]),_c('el-menu-item',{attrs:{"index":"/dashboard/fee-statistics"}},[_vm._v(" Fee statistics ")]),_c('el-menu-item',{attrs:{"index":"/dashboard/psp-balances"}},[_vm._v(" PSP balances ")])],2):_vm._e(),(_vm.$can('FINANCE_ADMIN') || _vm.$can('B2B_BRAND_ADMIN'))?_c('el-submenu',{attrs:{"index":"/b2b"}},[_c('template',{slot:"title"},[_vm._v("B2B")]),(_vm.$can('FINANCE_ADMIN') || _vm.$can('B2B_BRAND_ADMIN'))?_c('el-menu-item',{attrs:{"index":"/b2b/brand"}},[_vm._v(" Brand ")]):_vm._e(),(_vm.$can('FINANCE_ADMIN'))?_c('el-menu-item',{attrs:{"index":"/b2b/mapping-payment-providers"}},[_vm._v(" Mapping payment providers ")]):_vm._e(),(_vm.$can('FINANCE_ADMIN'))?_c('el-menu-item',{attrs:{"index":"/b2b/mapping-game-providers"}},[_vm._v(" Mapping game providers ")]):_vm._e()],2):_vm._e(),(_vm.$can('FINANCE_ADMIN'))?_c('el-menu-item',{attrs:{"index":"/psp-accounts"}},[_vm._v("PSP Accounts")]):_vm._e(),(_vm.$can('FINANCE_ADMIN'))?_c('el-menu-item',{attrs:{"index":"/quickbooks-transfer"}},[_vm._v(" Quick books transfer ")]):_vm._e(),(_vm.$can('FINANCE_ADMIN'))?_c('el-menu-item',{attrs:{"index":"/game-providers"}},[_vm._v(" Game providers ")]):_vm._e(),(_vm.$can('FINANCE_ADMIN'))?_c('el-menu-item',{attrs:{"index":"/logging"}},[_vm._v(" Logging ")]):_vm._e(),(
        _vm.$can('AFFILIATE_ACCOUNTANT') ||
        _vm.$can('AFFILIATE_FINANCE_BASIC') ||
        _vm.$can('AFFILIATE_SUPER_ADMIN')
      )?_c('el-submenu',{attrs:{"index":"/affiliate"}},[_c('template',{slot:"title"},[_vm._v("Affiliate")]),(
          _vm.$can('AFFILIATE_ACCOUNTANT') ||
          _vm.$can('AFFILIATE_FINANCE_BASIC') ||
          _vm.$can('AFFILIATE_SUPER_ADMIN')
        )?_c('el-menu-item',{attrs:{"index":"/affiliate/register"}},[_vm._v(" Register ")]):_vm._e(),(
          _vm.$can('AFFILIATE_FINANCE_BASIC') ||
          _vm.$can('AFFILIATE_SUPER_ADMIN')
        )?_c('el-menu-item',{attrs:{"index":"/affiliate/entity"}},[_vm._v(" Entity ")]):_vm._e(),(
          _vm.$can('AFFILIATE_FINANCE_BASIC') ||
          _vm.$can('AFFILIATE_SUPER_ADMIN')
        )?_c('el-menu-item',{attrs:{"index":"/affiliate/group"}},[_vm._v(" Group ")]):_vm._e(),(
          _vm.$can('AFFILIATE_FINANCE_BASIC') ||
          _vm.$can('AFFILIATE_SUPER_ADMIN')
        )?_c('el-menu-item',{attrs:{"index":"/affiliate/company"}},[_vm._v(" Company ")]):_vm._e()],2):_vm._e()],1),_c('el-button',{staticClass:"app-menu__logout",attrs:{"type":"text"},on:{"click":_vm.logoutRequest}},[_vm._v(" Logout ")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-menu__logo"},[_c('img',{attrs:{"width":"110","src":require("@/assets/logo/logo--light.svg"),"alt":"Logo"}})])
}]

export { render, staticRenderFns }